import MessageIcon from '@mui/icons-material/Message';
import useMemberMessageSummary from "../../../../hooks/use-member-message-summary";
import StatCard from "../../../../components/StatCard";
import { StatSummary } from "../../../../components/StatSummary";

const MemberMessageSummary = () => {
    const messageSummary = useMemberMessageSummary();

    return (
        <div>
            {messageSummary &&
                <>
                    <StatSummary>
                        {/* <StatCard title={`Today`} value={messageSummary?.todayTotal.toLocaleString()} icon={MessageIcon} /> */}
                        <StatCard title={`Current Billing Cycle`} value={messageSummary?.currentCycleTotal.toLocaleString()} icon={MessageIcon} />
                        <StatCard title={`Remaining Free Quota`} value={messageSummary?.freeQuotaRemaining.toLocaleString()} icon={MessageIcon} />
                        {/* <StatCard title={`This Year`} value={messageSummary?.currentYearTotal.toLocaleString()} icon={MessageIcon} /> */}
                    </StatSummary>
                    {/* <Grid container direction="row" justifyContent="flex-start">
                        <Grid item lg={5} md={6} sm={12} xs={12}>
                            <ChartCard><SingleBarChart data={messageSummary.monthlyTotals.map(item => { return { name: item.key, value: item.value } })} label={`Monthly Income (${CURRENCY})`} height={204} xAxisAngle={-90} /></ChartCard>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={messageSummary.totalByMembership.map(item => { return { name: item.key, value: item.value } })} label='Income by Membership' /></ChartCard>
                        </Grid>
                    </Grid> */}
                </>
            }
        </div>
    )
}

export default MemberMessageSummary;