import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import theme from '../../app/app-theme';

interface TableActionButtonProps {
    readonly onClick: () => void;
    readonly icon: React.ReactNode;
    readonly label: string;
}

const TableActionButton: React.FC<TableActionButtonProps> = ({ icon, label, onClick }) => {

    return (
        <Tooltip title={label} disableInteractive>
            <IconButton onClick={onClick} sx={{ color: `${theme.palette.secondary.main}` }} className="table-action-button">{icon}</IconButton>
        </Tooltip>
    );
}

export default TableActionButton;