import { useAppSelector } from "../app/hooks";
import * as authSlice from "../features/auth/auth.slice";
import AdminDashboard from "../features/dashboard/admin-dashboard";
import UserDashboard from "../features/dashboard/user-dashboard";
import { UserRoles } from "../shared/enums";

const Dashboard = () => {
    const loggedInUser = useAppSelector(authSlice.selectLoggedInUser);
    const permissions = loggedInUser?.user.roles.map(role => role.name) as UserRoles[] ?? [];

    return (
        <div>
            {permissions.includes(UserRoles.Admin) &&
                <AdminDashboard />
            }
            {permissions.includes(UserRoles.Owner) &&
                <UserDashboard />
            }
            {permissions.includes(UserRoles.Staff) &&
                <UserDashboard />
            }
        </div>
    )
}

export default Dashboard;