import { Box, TableCell } from "@mui/material";
import StyledTableRow from "../../../components/table/styled-table-row";
import { User } from "../../../service/model/user.model";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import UserForm from "./user-form";
import { ConfirmationDialog } from "../../../components/confiramation-dialog";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { deleteUser, updateUser } from "../user.slice";
import { selectLoggedInUser } from "../../auth/auth.slice";
import TableActionButton from "../../../components/table/table-action-button";

interface TableRowProps {
    readonly user: User
}

const UserTableRow: React.FC<TableRowProps> = ({ user }) => {
    const dispatch = useAppDispatch();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showUserForm, setShowUserForm] = useState(false);
    const loggedInUserDetails = useAppSelector(selectLoggedInUser);
    const loggedInUser = loggedInUserDetails?.user;

    const handleDelete = () => {
        dispatch(deleteUser(user.id))
        setShowDeleteDialog(false)
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const handleSubmit = (data: User) => {
        loggedInUser && dispatch(updateUser({ ...data, id: user.id, businessId: loggedInUser.businessId })).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowUserForm(false);
            }
        });
    };

    return (
        <>
            <StyledTableRow key={user.id}>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Username</Box>{user.username}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">First Name</Box>{user.firstName}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Last Name</Box>{user.lastName}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Contact</Box>{user.contact}</TableCell>
                {/* <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Expiry</Box>{node.branch.createdDate}</TableCell> */}
                <TableCell>
                    <Box component="div" display="inline" className="responsive-mobile-heading">Actions</Box>
                    {/* <IconButton onClick={() => setShowUserForm(true)} sx={{color: `${theme.palette.secondary.main}`, paddingLeft: 0}}><VisibilityIcon /></IconButton> */}
                    <TableActionButton onClick={() => setShowUserForm(true)} label="Edit" icon={<EditIcon />} />
                    <TableActionButton onClick={() => setShowDeleteDialog(true)} label="Delete" icon={<DeleteIcon />} />
                    {/* <Link className="btn" to="#" data-toggle="modal" data-target="#delete-member-modal" onClick={() => setShowDeleteDialog(true)}><DeleteIcon /></Link> */}
                </TableCell>
            </StyledTableRow>
            {showUserForm &&
                <UserForm user={user} editable={true} onClose={() => setShowUserForm(false)} onSubmit={handleSubmit} />
            }
            {showDeleteDialog &&
                <ConfirmationDialog show={true} message="Are you sure you want to delete?" onConfirm={handleDelete} onCancel={handleDeleteCancel} />
            }
        </>
    );
}

export default UserTableRow;