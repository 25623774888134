import { Box, TableCell, Tooltip } from "@mui/material";
import StyledTableRow from "../../../../components/table/styled-table-row";
import { MemberMessage } from "../../../../service/model/message.model";
import CopyToClipboard from "../../../../components/copy-to-clipboard";
import { MessageStatus } from "../../../../shared/enums";

import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

interface TableRowProps {
    readonly message: MemberMessage
}

const MemberMessageTableRow: React.FC<TableRowProps> = ({ message }) => {

    const getStatusIcon = (status: string) => {
        if(status === MessageStatus.READ) {
            return <Tooltip title={status}><VisibilityIcon color="success"/></Tooltip>
        } else if((status === MessageStatus.DELIVERED)) {
            return <Tooltip title={status}><DoneAllIcon color="action"/></Tooltip>
        } else if((status === MessageStatus.SENT)) {
            return <Tooltip title={status}><CheckIcon color="action"/></Tooltip>
        } else if((status === MessageStatus.FAILED) || (status === MessageStatus.UNDELIVERED)) {
            return <Tooltip title={status}><CloseIcon color="error"/></Tooltip>
        } else if((status === MessageStatus.QUEUED)) {
            return <Tooltip title={status}><PauseCircleOutlineIcon color="warning"/></Tooltip>
        } else {
            return <Tooltip title={status}><QuestionMarkIcon color="action"/></Tooltip>
        }
    }

    return (
        <StyledTableRow key={message.id}>
            <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Id</Box><CopyToClipboard value={message.messageSid} tooltip="Copy ID to clipboard"/></TableCell>
            <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Member</Box>{`${message.member.firstName} ${message.member.lastName}`}</TableCell>
            <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Message</Box>{message.messageContent}</TableCell>
            <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Channel</Box>{message.channel}</TableCell>
            <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Count</Box>{message.messageCost}</TableCell>
            <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Timestamp</Box>{message.createdOn.replace("T", " ")}</TableCell>
            <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Status</Box>{getStatusIcon(message.status)}</TableCell>
        </StyledTableRow>
    );
}

export default MemberMessageTableRow;