import { Member } from '../../../../service/model/member.model';
import { MessageType } from '../../../../shared/enums';

// const getPaymentReminderMessage = (member: Member, business: string) => {
//     return `Friendly Reminder: Payment Due

// Hi ${member.firstName},

// We hope you're doing well! We wanted to remind you that your membership has expired, and we truly miss having you with us.

// Expired On: ${member.expiryDate}

// To continue enjoying our facilities and services, please consider renewing your membership. We have exciting new classes and programs waiting for you!

// If you have already made the payment, please disregard this message. Feel free to reach out if you have any questions or need assistance.

// We'd love to see you back!

// Stay healthy,
// ${business}`;
// }

const getDietPlanMessage = (member: Member, business: string) => {
    return `Hi ${member.firstName},

We are excited to share your personalized diet plan.

Diet Plan Overview:
- Breakfast:
- Lunch:
- Dinner: 
- Snacks: 
- Special Notes: 

Stay fit,
${business}`;
};

const getWorkoutScheduleMessage = (member: Member, business: string) => {
    return `Hi ${member.firstName},

We are excited to share your personalized workout schedule.
- Monday:
- Tuesday:
- Wednesday:
- Thursday:
- Friday:
- Saturday:
- Sunday:

${business}`;
};

export const getDefaultMessage = (
    selectedMessageType: string,
    member: Member,
    business: string
) => {
    if (selectedMessageType === MessageType.PAYMENT_REMINDER) {
        return '';
    } 
    // else if (selectedMessageType === MessageType.DIET_PLAN) {
    //     return getDietPlanMessage(member, business);
    // } else if (selectedMessageType === MessageType.WORKOUT_SCHEDULE) {
    //     return getWorkoutScheduleMessage(member, business);
    // } 
    else {
        return '';
    }
};
