import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as dashboardService from '../../service/dashboard.service'
import { AdminDashboardSummary } from "../../service/model/dashboard.model";

export interface AdminDashboardState {
    isLoading: boolean;
    summary?: AdminDashboardSummary;
}
  
export const initialState = ({
    isLoading: false,
} as AdminDashboardState);

export const fetchAdminDashboardSummary = createAsyncThunk<AdminDashboardSummary>(
    'dashboard/admin',
    async (_, {rejectWithValue}) => {
        return dashboardService.getAdminDashboardSummary().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

const adminDashboardSlice = createSlice({
    name: 'adminDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAdminDashboardSummary.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchAdminDashboardSummary.fulfilled, (state, action: PayloadAction<AdminDashboardSummary>) => {
            state.summary = action.payload;
            state.isLoading = false;
        })
        .addCase(fetchAdminDashboardSummary.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const {} = adminDashboardSlice.actions;

export const selectIsLoading = (state: RootState) => state.adminDashboard.isLoading;
export const selectAdminDashboardSummary = (state: RootState) => state.adminDashboard.summary;

export default adminDashboardSlice.reducer;