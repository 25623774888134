import axios from "axios";
import { configs } from "../app/app-configs";
import { AdminDashboardSummary, UserDashboardSummary } from "./model/dashboard.model";

export const getUserDashboardSummary = async (): Promise<UserDashboardSummary> => {
    return await axios.get(`${configs.BASE_URL}/dashboard/user`)
    .then(res => {
        return res.data as UserDashboardSummary;
    });
}

export const getAdminDashboardSummary = async (): Promise<AdminDashboardSummary> => {
    return await axios.get(`${configs.BASE_URL}/dashboard/admin`)
    .then(res => {
        return res.data as AdminDashboardSummary;
    });
}