import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { BusinessMessageSearchCriteria } from "../../../../service/model/message.model";
import { addDays } from "date-fns";
import BusinessMessageTable from "./business-message-table";
import * as messageSlice from "../../business-message.slice";
import { Business } from "../../../../service/model/business.model";
import { selectLoggedInUser } from "../../../auth/auth.slice";
import { MessageChannel, MessageType } from "../../../../shared/enums";

interface BusinessMessageModalProps {
    readonly business: Business
    readonly onClose: () => void
}

const mapMessageType = (type: string): string => {
    if (type === MessageType.PAYMENT_REMINDER) {
        return "payment_reminder";
    } else {
        return "";
    }
}

const style = {
    p: 2,
};

const validationSchema = yup.object().shape({
    messageType: yup.string().required("Type is required"),
    messageContent: yup.string(),
});

export type FormValues = yup.InferType<typeof validationSchema>;

const BusinessMessageModal: React.FC<BusinessMessageModalProps> = ({ business, onClose }) => {
    const dispatch = useAppDispatch();
    const messages = useAppSelector(messageSlice.selectMessages);
    const loggedInUser = useAppSelector(selectLoggedInUser);
    const [selectedChannel, setSelectedChannel] = useState(MessageChannel.SMS);
    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const messageType = watch("messageType");
    // const messageText = watch("messageContent");

    // const onSubmitHandler = (data: any) => {
    //     const request = {
    //         businessId: business.id,
    //         messageType: mapMessageType(data.messageType),
    //         messageContent: data.messageContent,
    //         channel: selectedChannel
    //     } as BusinessMessageRequest
    //     dispatch(messageSlice.sendMessageToBusiness(request))
    //     onClose();
    // };

    // const getRegister = (name: keyof FormValues, validation?: any): UseFormRegisterReturn => {
    //     return register(name, validation);
    // };

    useEffect(() => {
        const searchCriteria = {
            businessId: business.id,
            pagination: {
                page: 0,
                size: 10
            },
            from: addDays(new Date(), -30).toISOString().slice(0, 10),
            to: new Date().toISOString().slice(0, 10),
        } as BusinessMessageSearchCriteria;
        dispatch(messageSlice.fetchBusinessMessagePage(searchCriteria));
    }, []);


    const onClear = () => {
        setValue("messageContent", "");
        setSelectedChannel(MessageChannel.SMS)
    }

    useEffect(() => {
        if (messageType) {
            // const message = getDefaultMessage(messageType, business);

            // setValue("messageContent", message);
            setSelectedChannel(MessageChannel.SMS)
        }
    }, [messageType, business, loggedInUser, setValue]);

    return (
        <Dialog open={true} onClose={onClose} sx={style} fullWidth maxWidth="md">
            <DialogTitle>Message Details</DialogTitle>

            <Box sx={style}>
                <>
                    <IconButton aria-label="close" onClick={onClose} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                        <CloseIcon />
                    </IconButton>
                    {/* <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormField>
                            <DropdownInput
                                id={"type"}
                                label="Message Type"
                                defaultValue={"Payment Reminder"}
                                error={errors.messageType !== undefined}
                                helperText={errors.messageType?.message}
                                options={Object.values(MessageType).map(type => { return { label: type, value: type } })}
                                register={register("messageType")}
                            />
                        </FormField>
                        <FormField>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={selectedChannel}
                                row
                            >
                                <FormControlLabel value="sms" onClick={() => setSelectedChannel(MessageChannel.SMS)} control={<Radio />} label="SMS" />
                                {messageType === MessageType.PAYMENT_REMINDER &&
                                    <FormControlLabel value="whatsapp" onClick={() => setSelectedChannel(MessageChannel.WHATSAPP)} control={<Radio />} label="WhatsApp" />
                                }
                            </RadioGroup>
                        </FormField>
                        <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                            <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={onClear}>Clear</Button>
                            <Button type="submit" variant="contained" >Send</Button>
                        </Grid>
                    </form> */}
                </>
                <BusinessMessageTable businessId={business.id} messages={messages} />
            </Box>
        </Dialog>
    );
}

export default BusinessMessageModal;