import { Box, Chip, Grid, TableCell } from "@mui/material";
import StyledTableRow from "../../../components/table/styled-table-row";
import { Business } from "../../../service/model/business.model";
import MoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactNode, useState } from "react";
import BusinessForm from "./business-form";
import { ConfirmationDialog } from "../../../components/confiramation-dialog";
import { useAppDispatch } from "../../../app/hooks";
import { deleteBusiness, updateBusiness } from "../business.slice";
import BusinessPaymentForm from "../../business-payments/components/business-payment-form";
import { BusinessPayment } from "../../../service/model/business-payment.model";
import { createBusinessPayment } from "../../business-payments/business-payment.slice";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MessageIcon from '@mui/icons-material/Message';
import UserForm from "../../user/components/user-form";
import { User } from "../../../service/model/user.model";
import { createUser } from "../../user/user.slice";
import TableActionButton from "../../../components/table/table-action-button";
import BusinessMessageModal from "../../message/components/business/business-message-modal";

interface TableRowProps {
    readonly business: Business
}

type BusinessStatus = 'Trial' | 'Active' | 'Expired' | 'Deleted' | 'Suspended';

const BusinessTableRow: React.FC<TableRowProps> = ({ business }) => {
    const dispatch = useAppDispatch();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showBusinessForm, setShowBusinessForm] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showUserForm, setShowUserForm] = useState(false);

    const handleDelete = () => {
        dispatch(deleteBusiness(business.id))
        setShowDeleteDialog(false)
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const handleSubmit = (data: Business) => {
        dispatch(updateBusiness({ ...data, id: business.id }));
        setShowBusinessForm(false);
    };


    const handlePaymentSubmit = (data: BusinessPayment) => {
        dispatch(createBusinessPayment(data)).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowPaymentForm(false);
            }
        });
    };

    const handleUserSubmit = (data: User) => {
        dispatch(createUser({ ...data, businessId: business.id })).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowUserForm(false);
            }
        });
    };

    const getStatusChip = (status: BusinessStatus): ReactNode => {
        switch (status) {
            case "Trial": {
                return <Chip label={status} color="info" />
            }
            case "Active": {
                return <Chip label={status} color="success" />
            }
            case "Suspended": {
                return <Chip label={status} color="warning" />
            }
            case "Expired": {
                return <Chip label={status} color="error" />
            }
            case "Deleted": {
                return <Chip label={status} color="default" />
            }
            default: {
                return <Chip label={status} color="default" />
            }
        }
    }

    return (
        <>
            <StyledTableRow key={business.id}>    
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">ID</Box>{business.id}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Name</Box>{business.name}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Plan</Box>{business.plan.name}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Discount</Box>{`${business.discountPercentage}%`}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Due</Box>{business.dueAmount}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Message Count</Box>{business.messageCount}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Expire On</Box>{business.expiryDate}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Status</Box>{getStatusChip(business.status as BusinessStatus)}</TableCell>
                {/* <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Expiry</Box>{node.branch.createdDate}</TableCell> */}
                <TableCell>
                    <Box component="div" display="inline" className="responsive-mobile-heading">Actions</Box>
                        <Grid display="flex">
                            {/* <IconButton onClick={() => setShowBusinessForm(true)} sx={{color: `${theme.palette.secondary.main}`, paddingLeft: 0}}><VisibilityIcon /></IconButton> */}
                            <TableActionButton onClick={() => setShowPaymentForm(true)} label="Add Payment" icon={<MoneyIcon />} />
                            <TableActionButton onClick={() => setShowBusinessForm(true)} label="Edit" icon={<EditIcon />} />
                            <TableActionButton onClick={() => setShowMessageModal(true)} label="Send Message" icon={<MessageIcon />} />
                            <TableActionButton onClick={() => setShowUserForm(true)} label="Add User" icon={<PersonAddIcon />} />
                            <TableActionButton onClick={() => setShowDeleteDialog(true)} label="Delete" icon={<DeleteIcon />} />
                            {/* <Link className="btn" to="#" data-toggle="modal" data-target="#delete-member-modal" onClick={() => setShowDeleteDialog(true)}><DeleteIcon /></Link> */}
                        </Grid>
                </TableCell>
            </StyledTableRow>
            {showPaymentForm &&
                <BusinessPaymentForm payment={{ businessId: business.id, businessName: business.name } as BusinessPayment} editable={true} onClose={() => setShowPaymentForm(false)} onSubmit={handlePaymentSubmit} />
            }
            {showBusinessForm &&
                <BusinessForm business={business} editable={true} onClose={() => setShowBusinessForm(false)} onSubmit={handleSubmit} />
            }
            {showMessageModal &&
                <BusinessMessageModal business={business} onClose={() => setShowMessageModal(false)} />
            }
            {showUserForm &&
                <UserForm user={undefined} editable={true} onClose={() => setShowUserForm(false)} onSubmit={handleUserSubmit} />
            }
            {showDeleteDialog &&
                <ConfirmationDialog show={true} message={`Are you sure you want to delete ${business.name} ?`} onConfirm={handleDelete} onCancel={handleDeleteCancel} />
            }
        </>
    );
}

export default BusinessTableRow;