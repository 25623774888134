import { Box, TableCell } from "@mui/material";
import StyledTableRow from "../../../components/table/styled-table-row";
import { Attendance } from "../../../service/model/attendance.model";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import AttendanceForm from "./attendance-form";
import { ConfirmationDialog } from "../../../components/confiramation-dialog";
import { useAppDispatch } from "../../../app/hooks";
import { deleteAttendance, updateAttendance } from "../attendance.slice";
import TableActionButton from "../../../components/table/table-action-button";

interface TableRowProps {
    readonly attendance: Attendance
}

const AttendanceTableRow: React.FC<TableRowProps> = ({ attendance }) => {
    const dispatch = useAppDispatch();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showAttendanceForm, setShowAttendanceForm] = useState(false);

    const handleDelete = () => {
        dispatch(deleteAttendance(attendance.id))
        setShowDeleteDialog(false)
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const handleSubmit = (data: Attendance) => {
        dispatch(updateAttendance({ ...data, id: attendance.id, memberId: attendance.memberId })).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowAttendanceForm(false);
            }
        });
    };

    return (
        <>
            <StyledTableRow key={attendance.id}>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">First Name</Box>{attendance.firstName}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Last Name</Box>{attendance.lastName}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Date</Box>{attendance.date}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Time</Box>{attendance.time}</TableCell>
                {/* <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Expiry</Box>{node.branch.createdDate}</TableCell> */}
                <TableCell>
                    <Box component="div" display="inline" className="responsive-mobile-heading">Actions</Box>
                    {/* <IconButton onClick={() => setShowAttendanceForm(true)} sx={{color: `${theme.palette.secondary.main}`, paddingLeft: 0}}><VisibilityIcon /></IconButton> */}
                    <TableActionButton onClick={() => setShowAttendanceForm(true)} label="Edit" icon={<EditIcon />} />
                    <TableActionButton onClick={() => setShowDeleteDialog(true)} label="Delete" icon={<DeleteIcon />} />
                    {/* <Link className="btn" to="#" data-toggle="modal" data-target="#delete-member-modal" onClick={() => setShowDeleteDialog(true)}><DeleteIcon /></Link> */}
                </TableCell>
            </StyledTableRow>
            {showAttendanceForm &&
                <AttendanceForm attendance={attendance} editable={true} onClose={() => setShowAttendanceForm(false)} onSubmit={handleSubmit} />
            }
            {showDeleteDialog &&
                <ConfirmationDialog show={true} message="Are you sure you want to delete?" onConfirm={handleDelete} onCancel={handleDeleteCancel} />
            }
        </>
    );
}

export default AttendanceTableRow;