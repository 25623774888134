import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchAdminDashboardSummary, selectAdminDashboardSummary } from "../features/dashboard/admin-dashboard.slice";

const useAdminDashboardSummary = () => {
    const dispatch = useAppDispatch();
    const summary = useAppSelector(selectAdminDashboardSummary);

    const getAdminDashboardSummary = async () => {
        await dispatch(fetchAdminDashboardSummary());
    }

    useEffect(() => {
        getAdminDashboardSummary();
    }, []);

    return summary;
}
 
export default useAdminDashboardSummary;