import StatCard from "../../components/StatCard";
import { StatSummary } from "../../components/StatSummary";
import useAdminDashboardSummary from "../../hooks/use-admin-dashboard-summary";
import BusinessIcon from '@mui/icons-material/Business';
import PaidIcon from '@mui/icons-material/Paid';
import SmsIcon from '@mui/icons-material/Sms';

const AdminDashboard = () => {
    const summary = useAdminDashboardSummary();
    
    return (
        <>
            {summary &&
                <>
                    <StatSummary>
                        <StatCard title="Total Business" value={summary?.totalBusiness} icon={BusinessIcon} />
                        <StatCard title="Twilio Balance" value={summary?.twilioBalance} icon={PaidIcon} />
                        <StatCard title="SMS Balance" value={summary?.smsBalance} icon={SmsIcon} />
                    </StatSummary>
                    {/* <Grid container direction="row" justifyContent="flex-start">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <ChartCard><SingleBarChart data={summary.dailyAttendanceTrend.map(item => { return { name: item.key, value: item.value } })} label='Daily Attendance' height={204} xAxisInterval={7}/></ChartCard>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={summary.countByStatus.map(item => { return { name: item.key, value: item.value } })} label='Membership Status' /></ChartCard>
                        </Grid>
                    </Grid> */}
                </>
            }
        </>
    )
}

export default AdminDashboard;