import { Box, TableCell } from "@mui/material";
import StyledTableRow from "../../../components/table/styled-table-row";
import { BusinessPayment } from "../../../service/model/business-payment.model";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import BusinessPaymentForm from "./business-payment-form";
import { ConfirmationDialog } from "../../../components/confiramation-dialog";
import { useAppDispatch } from "../../../app/hooks";
import { deleteBusinessPayment, updateBusinessPayment } from "../business-payment.slice";
import TableActionButton from "../../../components/table/table-action-button";

interface TableRowProps {
    readonly payment: BusinessPayment
}

const BusinessPaymentTableRow: React.FC<TableRowProps> = ({ payment }) => {
    const dispatch = useAppDispatch();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showBusinessPaymentForm, setShowBusinessPaymentForm] = useState(false);

    const handleDelete = () => {
        dispatch(deleteBusinessPayment(payment.id))
        setShowDeleteDialog(false)
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const handleSubmit = (data: BusinessPayment) => {
        dispatch(updateBusinessPayment({...data, id: payment.id, businessId: payment.businessId})).then(resp => {
            if(resp.meta.requestStatus === 'fulfilled') {
                setShowBusinessPaymentForm(false);
            }
        });
    };

    return (
        <>
            <StyledTableRow key={payment.id}>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Business</Box>{payment.businessName}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Amount</Box>{payment.amount}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Payment Date</Box>{payment.paymentDate}</TableCell>
                {/* <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Expiry</Box>{node.branch.createdDate}</TableCell> */}
                <TableCell>
                    <Box component="div" display="inline" className="responsive-mobile-heading">Actions</Box>
                    {/* <IconButton onClick={() => setShowBusinessPaymentForm(true)} sx={{color: `${theme.palette.secondary.main}`, paddingLeft: 0}}><VisibilityIcon /></IconButton> */}
                    <TableActionButton onClick={() => setShowBusinessPaymentForm(true)} label="Edit" icon={<EditIcon />}/>
                    <TableActionButton onClick={() => setShowDeleteDialog(true)} label="Delete" icon={<DeleteIcon />}/>
                    {/* <Link className="btn" to="#" data-toggle="modal" data-target="#delete-member-modal" onClick={() => setShowDeleteDialog(true)}><DeleteIcon /></Link> */}
                </TableCell>
            </StyledTableRow>
            {showBusinessPaymentForm &&
                <BusinessPaymentForm payment={payment} editable={true} onClose={() => setShowBusinessPaymentForm(false)} onSubmit={handleSubmit}/>
            }
            {showDeleteDialog &&
                <ConfirmationDialog show={true} message="Are you sure you want to delete?" onConfirm={handleDelete} onCancel={handleDeleteCancel}/>
            }
        </>
    );
}

export default BusinessPaymentTableRow;